import dpasaLogo from '~assets/dpasa.svg';
const CONFIGURATION = {
    name: 'Kalk',
    key: 'dpasa',
    motto: 'Solving Crypto Economic Challenges',
    logo: dpasaLogo,
    linkPrivacyPolicy: 'https://unbounded.network/privacy',
    linkTermsOfUse: 'https://unbounded.network/terms',
};
export default CONFIGURATION;
