import unboundedLogo from '~assets/unbounded.svg';
const CONFIGURATION = {
    name: 'Unbounded Network',
    key: 'unbounded',
    motto: 'Every network. Connected.',
    logo: unboundedLogo,
    linkPrivacyPolicy: 'https://unbounded.network/privacy',
    linkTermsOfUse: 'https://unbounded.network/terms',
};
export default CONFIGURATION;
